import $ from 'jquery';
import MotifEditor from "./motif-editor";
import config from './config';

const pdfRegEx = /application\/pdf/;
const imageScalingFactor = 0.05;
const mimeTypes = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'pdf': 'application/pdf'
};

export default class OwnMotifEditor extends MotifEditor {

    $uploadArea;
    $fileInput;
    $btnCenter;
    lastUploadedFile = null;
    imageId = null;

    constructor($container) {
        super($container);

        this.$uploadArea = $container.find('.c-upload-target');

        this.$uploadArea
            .on('dragover', e => this._handleDragOver(e))
            .on('drop', e => this._handleDrop(e));

        this.$btnCenter = $container.find('.c-center');
        this.$btnCenter.click(this._handleCenterImage.bind(this));

        this.$fileInput = $(this.$uploadArea.find('input[type="file"]')[0]);
        this.$fileInput.attr('accept', this._getAllowedMimeTypes().join(','))
            .change(this._handleFileInputChanged.bind(this, this.$fileInput[0]));
    }

    async initialize(creatorId, docId, name, theme) {
        await super.initialize(creatorId, docId, name, theme);
    }

    async destroy(asynchronous = true) {
        this.lastUploadedFile = null;
        this.imageId = null;
        this.$uploadArea.off();
        this.$fileInput.off();
        this.$btnCenter.off();
        await super.destroy(asynchronous);
    }

    async _handleWorkspaceLoaded() {
        super._handleWorkspaceLoaded();
        this._toggleView(false);
    }

    _toggleView(showCanvas = true) {
        if (showCanvas) {
            this.$canvasContainer.removeClass('hidden');
            this.$uploadArea.hide();
            this.$uploadArea.parent().addClass('hidden');

        } else {
            this.$canvasContainer.addClass('hidden');
            //this.$uploadArea.show();
            this.$uploadArea.fadeIn();
            this.$uploadArea.parent().removeClass('hidden');
        }
    }

    getLastUploadedFile() {
        return this.lastUploadedFile;
    }

    _handleReset() {
        return super._handleReset().then(() => this._resetFileInput());
    }

    async _handleCenterImage() {
        await this.cApp.resetImage(this.imageId);
    }

    _resetFileInput() {
        this._toggleView(false);
        this.$uploadArea.siblings().filter('.lds-ripple').hide();
    }

    _handleFileInputChanged(input) {
        if (input.files && input.files.length === 1) {
            this._handleChosenUploadFile(input.files[0]);
        }
    };

    _handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
        let dt = e.originalEvent.dataTransfer;

        dt.dropEffect = (dt.items.length === 1 && $.inArray(dt.items[0].type, this._getAllowedMimeTypes()) >= 0)
            ? 'copy'
            : 'none';
    };

    _handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        let dt = e.originalEvent.dataTransfer;
        if (dt.files.length === 1 && $.inArray(dt.items[0].type, this._getAllowedMimeTypes()) >= 0) {
            let file = dt.files[0];
            this._handleChosenUploadFile(file);
        }
    };

    _getAllowedMimeTypes() {
        const allowedFileMimeTypes = [];
        Object.values(config.motifFileTypes).forEach(function (type) {
            allowedFileMimeTypes.push(mimeTypes[type]);
        });
        return allowedFileMimeTypes;
    }

    _handleChosenUploadFile(file) {
        let fr = new FileReader();
        fr.onload = () => {
            fr.onload = null;
            if (pdfRegEx.test(file.type)) {
                this.$uploadArea.siblings().filter('.lds-ripple').fadeIn();
                this.cApp.addImage(1, file).then(id => {
                    this.lastUploadedFile = file;
                    this.imageId = id;
                    this._toggleView(true);
                    this._updateButtons();
                });
            } else {
                const img = new Image();
                img.onload = () => {
                    if (img.width < config.minImageSize && img.height < config.minImageSize) {
                        // both dimensions are too small
                        alert(config.messages._upload_to_small);
                    } else {
                        // either one or both dimensions are large enough
                        this.$uploadArea.siblings().filter('.lds-ripple').fadeIn();
                        this.cApp.addImage(1, file).then(id => {
                            this.lastUploadedFile = file;
                            this.imageId = id;
                            this._toggleView(true);
                            this._updateButtons();
                        });
                    }
                };
                // noinspection JSValidateTypes
                img.src = fr.result;
            }
            fr = null;
        };
        fr.readAsDataURL(file);
    }

    _updateButtons() {
        this.$btnCenter.off('click');
        this.$btnInc.off('click');
        this.$btnDec.off('click');

        if (this.imageId) {
            this.$btnCenter.click(() => this.cApp.resetImage(this.imageId))
            this.$btnInc.click(() => this.cApp.resizeImage(this.imageId, imageScalingFactor));
            this.$btnDec.click(() => this.cApp.resizeImage(this.imageId, -imageScalingFactor));

            this.$btnCenter.removeClass('c-disabled');
            this.$btnInc.removeClass('c-disabled');
            this.$btnDec.removeClass('c-disabled');
        }
    }


    __insertImageIntoView(file) {
        this.$uploadArea.fadeOut();
        this.$uploadArea.siblings().filter('.lds-ripple').show();
        let formData = new FormData();
        if (pdfRegEx.test(file.type)) {
            formData.append('convertTo', 'png');
        }

        formData.append('file', file);
        $.ajax({
            url: this.api.getBaseUrl() + '/workspace/' + this.api.workspaceId + '/images/upload?api_key=' + this.api.accessToken,
            data: formData,
            type: 'POST',
            contentType: false,
            processData: false
        }).done(data => {
            this.lastUploadedFile = file;
            this.api.resetWorkspace().then(() => {
                this.api.post(this.api.getPagePath() + '/canvas/images', {poolImageId: data.id}).then(data => {
                    this.api.get(this.api.getPagePath() + 'canvas/image/' + encodeURIComponent(data.id), {}).done((imageData) => {
                        this.__centerImageWithElementData(imageData).done(() => {
                            this.$canvasContainer.removeClass('hidden');
                            this.$uploadArea.parent().addClass('hidden');
                        });
                    });
                });
            });
        }).fail(() => {
            alert('Could not upload motif.');
        });
    }

    __centerImage(canvasElementId) {
        let canvasElement = this.til.renderer.element(canvasElementId);

        let position = canvasElement.position;
        let newWidth, newHeight;

        if (position.width > position.height) {
            newWidth = this.provider.width;
            newHeight = this.provider.height * (position.height / position.width);
        } else {
            newWidth = this.provider.width * (position.width / position.height);
            newHeight = this.provider.height;
        }

        let patchParams = {
            op: 'transform',
            unit: 'px',
            width: newWidth,
            height: newHeight,
            left: this.provider.width / 2.0,
            top: this.provider.height / 2.0,
            angle: canvasElement.rotation.angle
        };
        return this.api.patch(this.api.getPagePath() + 'canvas/element/' + canvasElementId, patchParams)
            // .then(() => this._refreshCanvas(canvasElementId))
            .done(() => this.__updateButtonStateForImageEdit(canvasElementId))
            .fail(() => console.error('could not center image'));
    };

    __centerImageWithElementData(element) {
        let boundary = element.base.boundary;
        let newWidth, newHeight;

        if (boundary.width > boundary.height) {
            newWidth = this.provider.width;
            newHeight = this.provider.height * (boundary.height / boundary.width);
        } else {
            newWidth = this.provider.width * (boundary.width / boundary.height);
            newHeight = this.provider.height;
        }

        let patchParams = {
            op: 'transform',
            unit: 'px',
            width: newWidth,
            height: newHeight,
            left: this.provider.width / 2.0,
            top: this.provider.height / 2.0,
            angle: element.base.attributes.angle
        };
        return this.api.patch(this.api.getPagePath() + 'canvas/element/' + element.base.id, patchParams)
            // .then(() => this._refreshCanvas())
            .fail(() => console.error('could not center image with element data'));
    }

    __updateButtonStateForImageEdit(canvasElementId) {
        let canvasElement = this.til.renderer.element(canvasElementId).position;

        this.$btnCenter.off('click');
        this.$btnCenter.click(() => this.__centerImage(canvasElementId)).removeClass('c-disabled');

        this.$btnInc.off('click');
        this.$btnDec.off('click');

        if (!this.__exceedsMaximumImageSize(canvasElement.width, canvasElement.height)) {
            this.$btnInc.click(() => this.__setImageSize(canvasElementId, imageScalingFactor)).removeClass('c-disabled');
        } else {
            this.$btnInc.addClass('c-disabled');
        }

        if (!this.__undercutsMinimumImageSize(canvasElement.width, canvasElement.height)) {
            this.$btnDec.click(() => this.__setImageSize(canvasElementId, -imageScalingFactor)).removeClass('c-disabled');
        } else {
            this.$btnDec.addClass('c-disabled');
        }
    };

    __setImageSize(canvasElementId, increment) {
        let id = canvasElementId;
        const canvasElement = this.til.renderer.element(canvasElementId);
        const position = canvasElement.position;
        let newWidth = increment > 0
            ? Math.min(position.width * (1 + increment), this.provider.width * 5)
            : Math.max(position.width * (1 + increment), this.provider.width / 10);
        let newHeight = increment > 0
            ? Math.min(position.height * (1 + increment), this.provider.height * 5)
            : Math.max(position.height * (1 + increment), this.provider.height / 10);
        let left = position.x + position.width / 2.0;
        let top = position.y + position.height / 2.0;

        let patchParams = {
            op: 'transform',
            unit: 'px',
            width: newWidth,
            height: newHeight,
            left: left,
            top: top,
            angle: canvasElement.rotation.angle
        };
        this.api.patch(this.api.getPagePath(1) + 'canvas/element/' + id, patchParams)
            // .then(() => this._refreshCanvas(id))
            .done(() => this.__updateButtonStateForImageEdit(canvasElementId))
            .fail(() => console.error('could not set image size'));
    };

    __canvasElementChanged(canvasElement) {
        if (!this.api.workspaceId) return;

        let pd = {
            op: 'transform',
            unit: 'px',
            width: (canvasElement.scaleX * canvasElement.width),
            height: (canvasElement.scaleY * canvasElement.height),
            left: canvasElement.left,
            top: canvasElement.top,
            angle: canvasElement.angle
        };

        if (canvasElement.tyres.type === 'image') {
            let ratio = pd.width / pd.height;

            if (this.__undercutsMinimumImageSize(pd.width, pd.height)) {
                if (ratio > 1) {
                    pd.height = this.provider.height / 10;
                    pd.width = pd.height * ratio;
                } else {
                    pd.width = this.provider.width / 10;
                    pd.height = pd.width / ratio;
                }
            } else if (this.__exceedsMaximumImageSize(pd.width, pd.height)) {
                if (ratio > 1) {
                    pd.height = this.provider.height * 5;
                    pd.width = pd.height * ratio;
                } else {
                    pd.width = this.provider.width * 5;
                    pd.height = pd.width / ratio;
                }
            }

            this.api.patch(this.api.getPagePath(1) + "canvas/element/" + canvasElement.id, pd)
                // .then(() => this._refreshCanvas(canvasElement.id))
                .done(() => this.__updateButtonStateForImageEdit(canvasElement.id))
                .fail(() => console.error('patch error'));
        } else {
            this.api.patch(this.api.getPagePath(1) + "canvas/element/" + canvasElement.id, pd)
                // .done(() => this._refreshCanvas(canvasElement.id))
                .fail(() => console.error('patch error'));
        }
    };

    __exceedsMaximumImageSize(width, height) {
        return width.toFixed(3) >= this.provider.width * 5 || height.toFixed(3) >= this.provider.height * 5;
    };

    __undercutsMinimumImageSize(width, height) {
        return width.toFixed(3) <= this.provider.width / 10 || height.toFixed(3) <= this.provider.height / 10;
    };
}

// import $ from 'jquery';
// import MotifEditor from "./motif-editor";
// import config from './config';
//
// const pdfRegEx = /application\/pdf/;
// const imageScalingFactor = 0.05;
// const mimeTypes = {
//     'jpg': 'image/jpeg',
//     'jpeg': 'image/jpeg',
//     'png': 'image/png',
//     'pdf': 'application/pdf'
// };
//
// export default class OwnMotifEditor extends MotifEditor {
//
//     $uploadArea;
//     $fileInput;
//     $canvasContainer;
//     lastUploadedFile = null;
//     imageId = null;
//
//     constructor(accessToken, repository, apiBaseUrl, btnInc, btnDec, btnReset, btnCenter, colorOptions, $uploadArea, $canvasContainer) {
//         super(accessToken, repository, apiBaseUrl, btnInc, btnDec, btnReset, btnCenter, colorOptions, $canvasContainer);
//
//         this.$uploadArea = $uploadArea;
//
//         this.$uploadArea
//             .on('dragover', e => this._handleDragOver(e))
//             .on('drop', e => this._handleDrop(e));
//
//         this.$btnCenter.click(this._handleResetImage.bind(this));
//
//         this.$fileInput = $(this.$uploadArea.find('input[type="file"]')[0]);
//         this.$fileInput.attr('accept', this._getAllowedMimeTypes().join(','))
//             .change(this._handleFileInputChanged.bind(this, this.$fileInput[0]));
//     }
//
//     async initialize(creatorId, docId, name, theme) {
//         await super.initialize(creatorId, docId, name, theme);
//     }
//
//     async destroy(asynchronous = true) {
//         this.lastUploadedFile = null;
//         this.imageId = null;
//         this.$uploadArea.off();
//         this.$fileInput.off();
//         await super.destroy(asynchronous);
//     }
//
//     async _handleWorkspaceLoaded() {
//         await super._handleWorkspaceLoaded();
//         this._toggleView(false);
//     }
//
//     _toggleView(showCanvas = true) {
//         if (showCanvas) {
//             this.$canvasContainer.removeClass('hidden');
//             this.$uploadArea.hide();
//             this.$uploadArea.parent().addClass('hidden');
//
//         } else {
//             this.$canvasContainer.addClass('hidden');
//             this.$uploadArea.show();
//             this.$uploadArea.parent().removeClass('hidden');
//         }
//     }
//
//     getLastUploadedFile() {
//         return this.lastUploadedFile;
//     }
//
//     _handleReset() {
//         return super._handleReset().then(() => this._resetFileInput());
//     }
//
//     _handleResetImage() {
//         throw 'Not implemented.'
//     }
//
//     _resetFileInput() {
//         this.$canvasContainer.addClass('hidden');
//         this.$uploadArea.parent().removeClass('hidden');
//         this.$uploadArea.siblings().filter('.lds-ripple').hide();
//         this.$uploadArea.fadeIn();
//     }
//
//     _handleFileInputChanged(input) {
//         if (input.files && input.files.length === 1) {
//             this._handleChosenUploadFile(input.files[0]);
//         }
//     };
//
//     _handleDragOver(e) {
//         e.preventDefault();
//         e.stopPropagation();
//         let dt = e.originalEvent.dataTransfer;
//
//         dt.dropEffect = (dt.items.length === 1 && $.inArray(dt.items[0].type, this._getAllowedMimeTypes()) >= 0)
//             ? 'copy'
//             : 'none';
//     };
//
//     _handleDrop(e) {
//         e.preventDefault();
//         e.stopPropagation();
//
//         let dt = e.originalEvent.dataTransfer;
//         if (dt.files.length === 1 && $.inArray(dt.items[0].type, this._getAllowedMimeTypes()) >= 0) {
//             let file = dt.files[0];
//             this._handleChosenUploadFile(file);
//         }
//     };
//
//     _getAllowedMimeTypes() {
//         const allowedFileMimeTypes = [];
//         Object.values(config.motifFileTypes).forEach(function (type) {
//             allowedFileMimeTypes.push(mimeTypes[type]);
//         });
//         return allowedFileMimeTypes;
//     }
//
//     _handleChosenUploadFile(file) {
//         let fr = new FileReader();
//         fr.onload = () => {
//             fr.onload = null;
//             if (pdfRegEx.test(file.type)) {
//                 this.cApp.addImage(1, file).then(id => {
//                     this.lastUploadedFile = file;
//                     this.imageId = id;
//                     this._toggleView(true);
//                     this._updateButtons();
//                 });
//             } else {
//                 const img = new Image();
//                 img.onload = () => {
//                     if (img.width < config.minImageSize && img.height < config.minImageSize) {
//                         // both dimensions are too small
//                         alert(config.messages._upload_to_small);
//                     } else {
//                         // either one or both dimensions are large enough
//                         this.cApp.addImage(1, file).then(id => {
//                             this.lastUploadedFile = file;
//                             this.imageId = id;
//                             this._toggleView(true);
//                             this._updateButtons();
//                         });
//                     }
//                 };
//                 // noinspection JSValidateTypes
//                 img.src = fr.result;
//             }
//             fr = null;
//         };
//         fr.readAsDataURL(file);
//     }
//
//     _updateButtons() {
//         this.$btnCenter.off('click');
//         this.$btnInc.off('click');
//         this.$btnDec.off('click');
//
//         if (this.imageId) {
//             this.$btnCenter.click(() => this.cApp.resetImage(this.imageId))
//             this.$btnInc.click(() => this.cApp.resizeImage(this.imageId, imageScalingFactor));
//             this.$btnDec.click(() => this.cApp.resizeImage(this.imageId, -imageScalingFactor));
//
//             this.$btnCenter.removeClass('c-disabled');
//             this.$btnInc.removeClass('c-disabled');
//             this.$btnDec.removeClass('c-disabled');
//         }
//     }
//
//
//     __insertImageIntoView(file) {
//         this.$uploadArea.fadeOut();
//         this.$uploadArea.siblings().filter('.lds-ripple').show();
//         let formData = new FormData();
//         if (pdfRegEx.test(file.type)) {
//             formData.append('convertTo', 'png');
//         }
//
//         formData.append('file', file);
//         $.ajax({
//             url: this.api.getBaseUrl() + '/workspace/' + this.api.workspaceId + '/images/upload?api_key=' + this.api.accessToken,
//             data: formData,
//             type: 'POST',
//             contentType: false,
//             processData: false
//         }).done(data => {
//             this.lastUploadedFile = file;
//             this.api.resetWorkspace().then(() => {
//                 this.api.post(this.api.getPagePath() + '/canvas/images', {poolImageId: data.id}).then(data => {
//                     this.api.get(this.api.getPagePath() + 'canvas/image/' + encodeURIComponent(data.id), {}).done((imageData) => {
//                         this.__centerImageWithElementData(imageData).done(() => {
//                             this.$canvasContainer.removeClass('hidden');
//                             this.$uploadArea.parent().addClass('hidden');
//                         });
//                     });
//                 });
//             });
//         }).fail(() => {
//             alert('Could not upload motif.');
//         });
//     }
//
//     __centerImage(canvasElementId) {
//         let canvasElement = this.til.renderer.element(canvasElementId);
//
//         let position = canvasElement.position;
//         let newWidth, newHeight;
//
//         if (position.width > position.height) {
//             newWidth = this.provider.width;
//             newHeight = this.provider.height * (position.height / position.width);
//         } else {
//             newWidth = this.provider.width * (position.width / position.height);
//             newHeight = this.provider.height;
//         }
//
//         let patchParams = {
//             op: 'transform',
//             unit: 'px',
//             width: newWidth,
//             height: newHeight,
//             left: this.provider.width / 2.0,
//             top: this.provider.height / 2.0,
//             angle: canvasElement.rotation.angle
//         };
//         return this.api.patch(this.api.getPagePath() + 'canvas/element/' + canvasElementId, patchParams)
//             // .then(() => this._refreshCanvas(canvasElementId))
//             .done(() => this.__updateButtonStateForImageEdit(canvasElementId))
//             .fail(() => console.error('could not center image'));
//     };
//
//     __centerImageWithElementData(element) {
//         let boundary = element.base.boundary;
//         let newWidth, newHeight;
//
//         if (boundary.width > boundary.height) {
//             newWidth = this.provider.width;
//             newHeight = this.provider.height * (boundary.height / boundary.width);
//         } else {
//             newWidth = this.provider.width * (boundary.width / boundary.height);
//             newHeight = this.provider.height;
//         }
//
//         let patchParams = {
//             op: 'transform',
//             unit: 'px',
//             width: newWidth,
//             height: newHeight,
//             left: this.provider.width / 2.0,
//             top: this.provider.height / 2.0,
//             angle: element.base.attributes.angle
//         };
//         return this.api.patch(this.api.getPagePath() + 'canvas/element/' + element.base.id, patchParams)
//             // .then(() => this._refreshCanvas())
//             .fail(() => console.error('could not center image with element data'));
//     }
//
//     __updateButtonStateForImageEdit(canvasElementId) {
//         let canvasElement = this.til.renderer.element(canvasElementId).position;
//
//         this.$btnCenter.off('click');
//         this.$btnCenter.click(() => this.__centerImage(canvasElementId)).removeClass('c-disabled');
//
//         this.$btnInc.off('click');
//         this.$btnDec.off('click');
//
//         if (!this.__exceedsMaximumImageSize(canvasElement.width, canvasElement.height)) {
//             this.$btnInc.click(() => this.__setImageSize(canvasElementId, imageScalingFactor)).removeClass('c-disabled');
//         } else {
//             this.$btnInc.addClass('c-disabled');
//         }
//
//         if (!this.__undercutsMinimumImageSize(canvasElement.width, canvasElement.height)) {
//             this.$btnDec.click(() => this.__setImageSize(canvasElementId, -imageScalingFactor)).removeClass('c-disabled');
//         } else {
//             this.$btnDec.addClass('c-disabled');
//         }
//     };
//
//     __setImageSize(canvasElementId, increment) {
//         let id = canvasElementId;
//         const canvasElement = this.til.renderer.element(canvasElementId);
//         const position = canvasElement.position;
//         let newWidth = increment > 0
//             ? Math.min(position.width * (1 + increment), this.provider.width * 5)
//             : Math.max(position.width * (1 + increment), this.provider.width / 10);
//         let newHeight = increment > 0
//             ? Math.min(position.height * (1 + increment), this.provider.height * 5)
//             : Math.max(position.height * (1 + increment), this.provider.height / 10);
//         let left = position.x + position.width / 2.0;
//         let top = position.y + position.height / 2.0;
//
//         let patchParams = {
//             op: 'transform',
//             unit: 'px',
//             width: newWidth,
//             height: newHeight,
//             left: left,
//             top: top,
//             angle: canvasElement.rotation.angle
//         };
//         this.api.patch(this.api.getPagePath(1) + 'canvas/element/' + id, patchParams)
//             // .then(() => this._refreshCanvas(id))
//             .done(() => this.__updateButtonStateForImageEdit(canvasElementId))
//             .fail(() => console.error('could not set image size'));
//     };
//
//     __canvasElementChanged(canvasElement) {
//         if (!this.api.workspaceId) return;
//
//         let pd = {
//             op: 'transform',
//             unit: 'px',
//             width: (canvasElement.scaleX * canvasElement.width),
//             height: (canvasElement.scaleY * canvasElement.height),
//             left: canvasElement.left,
//             top: canvasElement.top,
//             angle: canvasElement.angle
//         };
//
//         if (canvasElement.tyres.type === 'image') {
//             let ratio = pd.width / pd.height;
//
//             if (this.__undercutsMinimumImageSize(pd.width, pd.height)) {
//                 if (ratio > 1) {
//                     pd.height = this.provider.height / 10;
//                     pd.width = pd.height * ratio;
//                 } else {
//                     pd.width = this.provider.width / 10;
//                     pd.height = pd.width / ratio;
//                 }
//             } else if (this.__exceedsMaximumImageSize(pd.width, pd.height)) {
//                 if (ratio > 1) {
//                     pd.height = this.provider.height * 5;
//                     pd.width = pd.height * ratio;
//                 } else {
//                     pd.width = this.provider.width * 5;
//                     pd.height = pd.width / ratio;
//                 }
//             }
//
//             this.api.patch(this.api.getPagePath(1) + "canvas/element/" + canvasElement.id, pd)
//                 // .then(() => this._refreshCanvas(canvasElement.id))
//                 .done(() => this.__updateButtonStateForImageEdit(canvasElement.id))
//                 .fail(() => console.error('patch error'));
//         } else {
//             this.api.patch(this.api.getPagePath(1) + "canvas/element/" + canvasElement.id, pd)
//                 // .done(() => this._refreshCanvas(canvasElement.id))
//                 .fail(() => console.error('patch error'));
//         }
//     };
//
//     __exceedsMaximumImageSize(width, height) {
//         return width.toFixed(3) >= this.provider.width * 5 || height.toFixed(3) >= this.provider.height * 5;
//     };
//
//     __undercutsMinimumImageSize(width, height) {
//         return width.toFixed(3) <= this.provider.width / 10 || height.toFixed(3) <= this.provider.height / 10;
//     };
// }