import $ from 'jquery';

const $languageOpenButton = $('nav .c-selected-language');
const $languageCloseButton = $('nav .c-close-language-selection');
const $languageSelection = $('.c-language-selection');

$languageOpenButton.click(function () {
    $languageSelection.hide();
    $languageSelection.removeClass('hidden');
    $languageSelection.slideDown();
    $languageOpenButton.addClass('hidden');
    $languageCloseButton.removeClass('hidden');
});
$languageCloseButton.click(function () {
    $languageSelection.slideUp();
    $languageOpenButton.removeClass('hidden');
    $languageCloseButton.addClass('hidden');
});

let $tcElement = $('.c-tc-confirmation-input strong');
let tcElementText = $tcElement.text();
$tcElement.replaceWith($('<a href="#" data-toggle="modal" data-target="#c-imprint-modal"></a>').text(tcElementText));
