import $ from 'jquery';

export default class MotifEditor {

    $container;
    $btnInc;
    $btnDec;
    $btnReset;
    $colorOptions;

    templateId = null;
    name = 'n/a';
    theme = 'n/a';
    color;
    accessToken;

    cApp;
    createdDocumentId;
    createdPermalinkId;

    constructor($container) {
        if (new.target === MotifEditor) {
            throw new TypeError('Cannot construct abstract instance.');
        }
        this.$container = $container;

        // this.canvas.targetFindTolerance = 3;
        // this.canvas.selection = false;
        // this.canvas.preserveObjectStacking = true;

        this.$btnInc = this.$container.find('.c-increase');
        this.$btnDec = this.$container.find('.c-decrease');
        this.$btnReset = this.$container.find('.c-reset');
        this.$colorOptions = this.$container.find('.c-color-option');
        this.$canvasContainer = this.$container.find('.c-canvas-container');
    }

    async initialize(creatorId, templateId, name, theme) {
        this.cApp = await colopCreator6.loadById(creatorId);

        this.templateId = templateId;
        this.name = name;
        this.theme = theme;

        this.cApp.setWorkspaceLoadedHandler(this._handleWorkspaceLoaded.bind(this));
    }

    async _handleWorkspaceLoaded() {
        this.cApp.setWorkspaceLoadedHandler(null);
        this.$canvasContainer.addClass('c-canvas-ready');

        this.$btnReset.click(() => this._handleReset());
        this.$colorOptions.click(this._handlePadColorSelection.bind(this));
    }

    async save() {
        if (this.createdDocumentId) {
            return {documentId: this.createdDocumentId, permalinkId: this.createdPermalinkId};
        } else {
            const response = await this.cApp.saveWorkspaceToDocument();
            this.createdDocumentId = response.documentId;
            this.createdPermalinkId = response.permalinkId;
            return response;
        }
    }

    getWsPreviewUrl() {
        return this.cApp.getPreviewImageUrl(1);
    }

    async destroy(asynchronous = true) {
        if (this.cApp) {
            if (asynchronous) {
                this.cApp.deleteWorkspace(null);
            } else {
                await this.cApp.deleteWorkspace(null);
            }
            this.cApp.unloadApp();
        }
        this.cApp = null;
        this.templateId = null;
        this.name = null;
        this.theme = null;

        this.$btnInc.off();
        this.$btnDec.off();
        this.$btnReset.off();
        this.$colorOptions.off();
    }

    async _handlePadColorSelection(event) {
        const color = $(event.currentTarget).data('colorcode');
        return this.cApp.setPadColor(1, color);
    }

    _handleReset() {
        return this.cApp.resetWorkspace();
    }
}

// import $ from 'jquery';
// import config from './config';
//
// export default class MotifEditor {
//
//     $btnInc;
//     $btnDec;
//     $btnReset;
//     $btnCenter;
//     $colorOptions;
//
//     templateId;
//     name;
//     theme;
//     color;
//     accessToken;
//
//     cApp;
//     createdDocumentId;
//
//     constructor(accessToken, repository, apiBaseUrl, btnInc, btnDec, btnReset, btnCenter, colorOptions, $canvasContainer) {
//         if (new.target === MotifEditor) {
//             throw new TypeError('Cannot construct abstract instance.');
//         }
//
//         // this.canvas.targetFindTolerance = 3;
//         // this.canvas.selection = false;
//         // this.canvas.preserveObjectStacking = true;
//
//         this.$btnInc = $(btnInc);
//         this.$btnDec = $(btnDec);
//         this.$btnReset = $(btnReset);
//         this.$btnCenter = $(btnCenter);
//         this.$colorOptions = $(colorOptions);
//         this.$canvasContainer = $($canvasContainer);
//
//         this.templateId = null;
//         this.name = 'n/a';
//         this.theme = 'n/a';
//         this.accessToken = accessToken;
//
//         this.$btnReset.click(() => this._handleReset());
//         this.$colorOptions.click(this._handlePadColorSelection.bind(this));
//     }
//
//     async initialize(creatorId, templateId, name, theme) {
//         this.cApp = await colopCreator6.loadById(creatorId);
//
//         this.templateId = templateId;
//         this.name = name;
//         this.theme = theme;
//
//         this.cApp.setWorkspaceLoadedHandler(this._handleWorkspaceLoaded.bind(this));
//     }
//
//     async _handleWorkspaceLoaded() {
//         this.cApp.setWorkspaceLoadedHandler(null);
//         this.$canvasContainer.addClass('.c-canvas-ready');
//     }
//
//     save() {
//         if (this.createdDocumentId) {
//             return new $.Deferred().resolve(this.createdDocumentId);
//         } else {
//             return this.api.saveDocument(config.repository).then((data) => {
//                 return this.createdDocumentId || (this.createdDocumentId = data.id);
//             });
//         }
//     }
//
//     getWsPreviewUrl() {
//         return '';
//     }
//
//     async destroy(asynchronous = true) {
//         if (this.cApp) {
//             if (asynchronous) {
//                 this.cApp.deleteWorkspace(null);
//             } else {
//                 await this.cApp.deleteWorkspace(null);
//             }
//             this.cApp.unloadApp();
//         }
//         this.cApp = null;
//         this.templateId = null;
//         this.name = null;
//         this.theme = null;
//
//         this.$btnInc.off();
//         this.$btnDec.off();
//         this.$btnReset.off();
//         this.$btnCenter.off();
//         this.$colorOptions.off();
//     }
//
//     async _handlePadColorSelection(event) {
//         const color = $(event.currentTarget).data('colorcode');
//         return this.cApp.setPadColor(1, color);
//     }
//
//     /**
//      * @return {Promise<void>}
//      */
//     _handleReset() {
//         return this.cApp.resetWorkspace();
//     }
// }