import $ from 'jquery';

export const storeUploadedFile = (file, orderId) => {
    if (!file) return;

    const url = 'store-file?' + $.param({orderId});

    const formData = new FormData();
    formData.append('file', file);

    return $.ajax({
        url,
        data: formData,
        type: 'POST',
        contentType: false,
        processData: false
    }).fail(() => {
        console.warn('Could not store motif.');
    });
};

export const getShippingCountries = (code, language) => {
    const url = 'shipping-countries?' + $.param({code, language});
    return $.get(url).fail(function () {
        console.log('Shipping countries could not be fetched...');
    });
}

export const validateData = (code, language, data) => {
    const url = 'validate?' + $.param({code, language});
    return $.post(url, data).fail(() => {
        alert("Unknown error occurred.\nCould not validate user data.");
    });
}

export const submitOrder = (code, language, documentId, permalinkId, data) => {
    const url = 'submit?' + $.param({code, language, documentId, permalinkId});
    return $.post(url, data).fail(() => {
        alert("Unknown error occurred.\nCould not submit order.");
    });
}

export const submitDocument = (data) => {
    return $.post('store-document', data)
}