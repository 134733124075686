import $ from 'jquery';
import config from './config';
import OwnMotifEditor from "./own-motif-editor";
import NormalMotifEditor from "./normal-motif-editor";
import {getShippingCountries, storeUploadedFile, submitDocument, submitOrder, validateData} from "./api";
import {dismissSessionTimeoutObservation, startSessionTimeoutObservation} from "../session-timeout";

if ($('.c-site-content').length) {

    startSessionTimeoutObservation(config.sessionLifetime);

    const $allTemplates = $('.c-template');
    const $btnOwnMotif = $('.c-own-motif-button');

    const $modal = $("#the-modal");

    const $designer = $modal.find('.c-designer');
    const $ownMotif = $modal.find('.c-own-motif');
    const $tryOutConfirmation = $modal.find('.c-try-out-confirmation');
    const $confirmation = $modal.find('.c-confirmation');
    const $shipping = $modal.find('.c-shipping');
    const $congratulations = $modal.find('.c-congratulations');

    const $btnGoToConfirmation = $designer.find('.c-next button');
    const $btnGoToShippingDetails = $confirmation.find('.c-next:not(.c-go-to-cart) button');
    const $btnGoToCart = $confirmation.find('.c-go-to-cart button');
    const $btnGoToCartText = $btnGoToCart.find('.c-button-action-text');
    const $btnGoToCartSpinner = $btnGoToCart.find('.glyphicon-refresh');
    const $confirmationErrorInfo = $confirmation.find('.c-error-info');
    const $btnGoBackToFirstPage = $modal.find('.c-previous');
    const $btnSubmitOrder = $shipping.find('.c-next button');
    const $highlightableElements = $confirmation.find('.c-confirmation-input');
    const confirmCheckbox = $highlightableElements.find('input')[0];
    const $confirmTcCheckboxElements = $shipping.find('.c-tc-confirmation-input');
    const confirmTcCheckbox = $confirmTcCheckboxElements.find('input')[0];
    const $btnGoToConfirmationOwnMotif = $ownMotif.find('.c-next button');
    const $highlightableElementsOwnMotif = $ownMotif.find('.c-upload-info');
    const $canvasContainer = $ownMotif.find('.c-canvas-container');

    let activeEditor = null;

    const showPanel = $panel => {
        $ownMotif.find('.lds-ripple').hide();
        $designer.find('.lds-ripple').hide();

        $ownMotif.hide();
        $designer.hide();
        $tryOutConfirmation.hide();
        $confirmation.hide();
        $shipping.hide();
        $congratulations.hide();

        $panel.fadeIn('fast');
        $panel.parent().scrollTop(0);
    };

    const hideLoader = ($img, $previewArea, $inUseArea) => {
        $previewArea.find('.lds-ripple').hide();
        $inUseArea.find('.lds-ripple').hide();

        $previewArea.prepend($img);

        const $inUseImg = $('<img alt="Preview" src="">').attr('src', $img.attr('src'));
        $inUseImg.hide();
        $inUseArea.prepend($inUseImg);
        $inUseImg.fadeIn('slow');
    };

    const showConfirmationPanel = function ($panel) {
        const $previewArea = $panel.parent().find('.c-preview-area');
        const $inUseArea = $panel.parent().find('.c-in-use-preview');
        $previewArea.find('img').remove();
        $inUseArea.find('img').remove();

        $previewArea.find('.lds-ripple').fadeIn();
        $inUseArea.find('.lds-ripple').fadeIn();

        activeEditor.getWsPreviewUrl().then(previewUrl => {
            showPanel($panel);
            $('<img alt="Preview" src="">').one('load', function () {
                hideLoader($(this), $previewArea, $inUseArea);
            }).attr('src', previewUrl + '&bump=' + new Date().getTime());
        })
    };

    const getEnteredData = function () {
        const enteredData = activeEditor instanceof OwnMotifEditor
            ? ['n/a']
            : activeEditor.$lines.find('input').map(function () {
                return $(this).val();
            }).get();

        const $firstName = $shipping.find('[name="NioOrder[first_name]"]');
        const $lastName = $shipping.find('[name="NioOrder[last_name]"]');
        const $street = $shipping.find('[name="NioOrder[street]"]');
        const $streetNo = $shipping.find('[name="NioOrder[street_no]"]');
        const $zip = $shipping.find('[name="NioOrder[zip]"]');
        const $place = $shipping.find('[name="NioOrder[place]"]');
        const $region = $shipping.find('[name="NioOrder[region]"]');
        const $country = $shipping.find('[name="NioOrder[country]"]');
        const $email = $shipping.find('[name="NioOrder[email]"]');
        const $phone = $shipping.find('[name="NioOrder[phone]"]');
        const $newsletter = $confirmation.find('.c-confirmation-input input[type="checkbox"]')[0];

        return {
            first_name: $firstName.length ? $firstName.val().trim() : '',
            last_name: $lastName.length ? $lastName.val().trim() : '',
            street: $street.length ? $street.val().trim() : '',
            street_no: $streetNo.length ? $streetNo.val().trim() : '',
            zip: $zip.length ? $zip.val().trim() : '',
            place: $place.length ? $place.val().trim() : '',
            region: $region.length ? $region.val().trim() : '',
            country: $country.length ? $country.val().trim() : '',
            email: $email.length ? $email.val().trim() : '',
            phone: $phone.length ? $phone.val().trim() : '',
            newsletter: $newsletter.checked ? 1 : 0,
            template: `${activeEditor.name} (${activeEditor.templateId})` || 'n/a',
            theme: activeEditor.theme || 'n/a',
            positive_design: 0,
            entered_data: enteredData,
            color: activeEditor.color || 'n/a',
        };
    };

    const submit = () => {
        const data = getEnteredData();

        $btnSubmitOrder.prop('disabled', true);

        validateData(config.code, config.language, data).then(response => {
            if (response.success) {
                activeEditor.save().then(({documentId, permalinkId}) => {
                    return submitOrder(config.code, config.language, documentId, permalinkId, data).done(response => {
                        if (response.success) {
                            dismissSessionTimeoutObservation();
                            showPanel($congratulations);
                            if (activeEditor instanceof OwnMotifEditor) {
                                setTimeout(storeUploadedFile, 0, activeEditor.getLastUploadedFile(), response.id);
                            }
                        } else {
                            alert(response.message);
                            $btnSubmitOrder.prop('disabled', false);
                        }
                    });
                }).catch(e => {
                    alert("Unknown error occurred.\nCould not save plate.");
                    console.log(e);
                });
            } else {
                alert(response.message);
                $btnSubmitOrder.prop('disabled', false);
            }
        });
    };

    const leaveSite = (documentId, previewUrl, productionUrl, padColor, templateName) => {
        const form = $('<form>', {
            action: config.returnUrl,
            method: 'post',
            target: config.returnTarget || '_top'
        });
        form.append($('<input>', {type: 'hidden', name: 'product_id', value: config.productId}));
        form.append($('<input>', {type: 'hidden', name: 'document_id', value: documentId}));
        form.append($('<input>', {type: 'hidden', name: 'pad_color', value: padColor}));
        form.append($('<input>', {type: 'hidden', name: 'preview_url', value: previewUrl}));
        form.append($('<input>', {type: 'hidden', name: 'production_url', value: productionUrl}));
        form.append($('<input>', {type: 'hidden', name: 'template_name', value: templateName}));

        if (config.passThrough) {
            (new URLSearchParams(config.passThrough)).forEach((value, key) => {
                form.append($('<input>', {type: 'hidden', name: key, value}));
            })
        }
        form.hide().appendTo('body').submit();
    };

    const disableCartButton = (disabled = true) => {
        $btnGoToCart.prop('disabled', disabled);
        $btnGoToCartText.toggleClass('hidden', disabled);
        $btnGoToCartSpinner.toggleClass('hidden', !disabled);
    };

    const storeDocument = () => {
        if (!confirmCheckbox.checked || $btnGoToCart.prop('disabled')) return;

        $confirmationErrorInfo.addClass('hidden');
        disableCartButton();

        activeEditor.save().then(({documentId, permalinkId}) => {
            const data = {
                productId: decodeURIComponent(config.productId),
                productName: decodeURIComponent(config.productName),
                ikey: decodeURIComponent(config.ikey),
                padColor: activeEditor.color,
                templateId: activeEditor.templateId,
                documentId,
                permalinkId,
            };
            if (config.storeDocumentData) {
                data.additional = config.storeDocumentData;
            }

            return submitDocument(data).done(response => {
                // Not implemented so far:
                // if (activeEditor instanceof OwnMotifEditor) {
                //     setTimeout(storeUploadedFile, 0, activeEditor.getLastUploadedFile(), response.id);
                // }
                $modal.find('.close-button').remove();
                $btnGoToCart.remove();
                $(confirmCheckbox).remove();
                const returnUrl = decodeURIComponent(config.returnUrl);
                if (/walmart\.com/.test(returnUrl))
                    window.top.location.href = returnUrl;
                else
                    leaveSite(documentId, response.previewUrl, response.productionUrl, activeEditor.color, `${activeEditor.name} (${activeEditor.templateId})`);
            }).fail(jqXHR => {
                if (jqXHR.responseJSON) {
                    //walmart
                    const data = jqXHR.responseJSON;
                    if ($confirmationErrorInfo.length) {
                        const $errorElement = $confirmationErrorInfo.filter('.pangaea-error-' + data.errorCode);
                        if ($errorElement.length > 0)
                            $errorElement.removeClass('hidden');
                        else
                            $confirmationErrorInfo.filter('.c-unknown-error').removeClass('hidden');
                    } else {
                        alert(jqXHR.responseJSON.message);
                    }
                } else {
                    alert(config.messages._general_error_no_continue);
                }
                setTimeout(() => disableCartButton(false), 0);
            });
        }).catch(e => {
            alert("Unknown error occurred.\nCannot continue.");
            console.log(e);
            setTimeout(() => disableCartButton(false), 0);
        });
    };

    const getThemeNames = element => {
        if ($(element).data('labels')) {
            const labelIds = $(element).data('labels').toString()
                .split(',')
                .map(v => v.trim())
                .filter(v => !!v)
                .map(v => parseInt(v));
            const themeNames = labelIds
                ? labelIds.reduce((arr, labelId) => {
                    if (config.themeLabels[labelId])
                        arr.push(config.themeLabels[labelId]);
                    return arr;
                }, []).join(',')
                : 'none';
            return themeNames || 'n/a';
        } else {
            return 'own-motif';
        }
    };

    const closeModal = () => {
        activeEditor.destroy();
        activeEditor = null;
        $designer.find('.c-canvas-container').empty();
    };

    const openModal = ($sourceTemplateElement, $initialPanel, creatorClass) => {
        if ($confirmation.length) {
            confirmCheckbox.checked = false;
            if (confirmTcCheckbox) confirmTcCheckbox.checked = false;
            $confirmationErrorInfo.addClass('hidden');
        }
        $btnGoBackToFirstPage.off().click(showPanel.bind(null, $initialPanel));

        const templateId = $sourceTemplateElement.data('docid');
        const templateName = $sourceTemplateElement.data('name') || 'blank';

        const $oldContainer = $initialPanel.find('.c-canvas-container');
        $oldContainer.empty();

        const id = 'creator6-ui-' + (Math.random() + 1).toString(36).substring(2);
        const $container = $('<div></div>').attr({
            id,
            class: 'c-canvas-container creator6-ui hidden',
            'data-repo': config.repository,
            'data-ct': 'canvas-only',
            'data-template-ids': templateId,
            'data-access-token': config.accessToken,
            'data-api-base-url': config.apiBaseUrl,
            'data-pad-color': config.padColor || '',

            'data-language': config.language,
            'data-locale-url': 'https://localcreator.colop.com/creator6/services/project/i18n/de.json',
        });
        $oldContainer.replaceWith($container);

        activeEditor = new creatorClass($initialPanel);
        activeEditor.initialize(id, templateId, templateName, getThemeNames($sourceTemplateElement))
            .then(() => {
                showPanel($initialPanel);
                $container.removeClass('hidden');

                $modal.modal({
                    show: true,
                    keyboard: false,
                    backdrop: 'static'
                });
                $modal.one('hide.bs.modal', closeModal);
            });
    };

    $btnGoToConfirmation.click(() => {
        $confirmationErrorInfo.addClass('hidden');
        const tryOut = $confirmation.length <= 0;
        showConfirmationPanel(tryOut ? $tryOutConfirmation : $confirmation);
    });

    $btnGoToCart
        .click(storeDocument)
        .on('mouseenter touchstart', () => {
            if (!confirmCheckbox.checked) $highlightableElements.addClass('c-highlight');
        })
        .on('mouseleave touchend', () => {
            $highlightableElements.removeClass('c-highlight');
        });

    $btnGoToShippingDetails
        .click(() => {
            if (confirmCheckbox.checked) showPanel($shipping);
        })
        .on('mouseenter touchstart', () => {
            if (!confirmCheckbox.checked) $highlightableElements.addClass('c-highlight');
        })
        .on('mouseleave touchend', () => {
            $highlightableElements.removeClass('c-highlight');
        });

    $btnSubmitOrder
        .click(() => {
            if (confirmTcCheckbox.checked) submit();
        })
        .on('mouseenter touchstart', () => {
            if (!confirmTcCheckbox.checked) $confirmTcCheckboxElements.addClass('c-highlight');
        })
        .on('mouseleave touchend', () => {
            $confirmTcCheckboxElements.removeClass('c-highlight');
        });


    $btnGoToConfirmationOwnMotif
        .click(() => {
            const tryOut = $confirmation.length <= 0;
            showConfirmationPanel(tryOut ? $tryOutConfirmation : $confirmation);
        })
        .on('mouseenter touchstart', () => {
            $highlightableElementsOwnMotif.addClass('c-highlight');
        })
        .on('mouseleave touchend', () => {
            $highlightableElementsOwnMotif.removeClass('c-highlight');
        });

    $allTemplates.click(function () {
        openModal($(this), $designer, NormalMotifEditor);
    });
    $btnOwnMotif.click(function () {
        openModal($(this), $ownMotif, OwnMotifEditor);
    });

    $(window).on('beforeunload', () => {
        if (activeEditor) activeEditor.destroy(false);
    });

    if (config.type === 'voucher') {
        getShippingCountries(config.code, config.language).done(data => {
            const $countrySelect = $modal.find('.c-shipping-address select[name="NioOrder[country]"]');
            $.each(data, (code, name) => {
                const $option = $('<option></option>').val(code).html(name);
                if (code === 'separator') $option.prop('disabled', true);
                $countrySelect.append($option);
            });
            // applyProcessingInstructions(config.processingInstructions);
        });
    }
}