export default window.colop && window.colop.nioSolution
    ? {
        sessionLifetime: window.colop.nioSolution.sessionLifetime,
        accessToken: window.colop.nioSolution.accessToken,
        repository: window.colop.nioSolution.repository,
        apiBaseUrl: window.colop.nioSolution.apiBaseUrl,
        code: window.colop.nioSolution.code,
        language: window.colop.nioSolution.language,
        returnUrl: window.colop.nioSolution.returnUrl,
        returnTarget: window.colop.nioSolution.returnTarget,
        productId: window.colop.nioSolution.productId,
        productName: window.colop.nioSolution.productName,
        padColor: window.colop.nioSolution.padColor,
        ikey: window.colop.nioSolution.ikey,
        passThrough: window.colop.nioSolution.passThrough,
        storeDocumentData: window.colop.nioSolution.storeDocumentData,
        themeLabels: window.colop.nioSolution.themeLabels,
        type: window.colop.nioSolution.type,
        processingInstructions: window.colop.nioSolution.processingInstructions,
        motifFileTypes: window.colop.nioSolution.motifFileTypes,
        imageFileTypes: window.colop.nioSolution.imageFileTypes,
        minImageSize: window.colop.nioSolution.minImageSize,

        messages: {
            _session_timeout: window.colop.nioSolution.messages._session_timeout,
            _general_error_no_continue: window.colop.nioSolution.messages._general_error_no_continue,
            _upload_to_small: window.colop.nioSolution.messages._upload_to_small,
            _upload_file_error: window.colop.nioSolution.messages._upload_file_error,
            _choose_motif: window.colop.nioSolution.messages._choose_motif,
        }
    }
    : {};