const absoluteMin = 6;
const absoluteMax = 60;

const minFontSizes = {
    "Anton": {w: 11, b: 10},
    "AntonLight": {w: 10, b: null},
    "ArchivoBlack": {w: 9, b: 9},
    "ArchivoNarrow": {w: null, b: null},
    "BacktoBlackDemo": {w: 9, b: 11},
    "Bitter": {w: 7, b: 9},
    "BlackJack": {w: 13, b: 13},
    "Bromello": {w: 11, b: 14},
    "Calligraffitti": {w: 10, b: 13},
    "Chalkduster": {w: 9, b: 10},
    "ChelseaMarket": {w: 8, b: 10},
    "ClarendonEinwaageBold": {w: 9, b: 9},
    "DeliusUnicase": {w: 8, b: 9},
    "FingerPaint": {w: 9, b: 11},
    "FredokaOne": {w: 9, b: 9},
    "Galada": {w: 9, b: 11},
    "JustAnotherHand": {w: null, b: null},
    "Lato": {w: 8, b: 8},
    "LatoBlack": {w: 8, b: 8},
    "LeckerliOne": {w: 10, b: 10},
    "LibreBodoni": {w: 7, b: 11},
    "LoveYaLikeASister": {w: 9, b: 9},
    "LuckiestGuy": {w: 10, b: 10},
    "PlayfairDisplay": {w: 8, b: 12},
    "RobotoCondensed": {w: 8, b: 9},
    "Rochester": {w: 9, b: 13},
    "SueEllenFrancisco": {w: 11, b: 12},
    "TrashHand": {w: 11, b: 13},
    "CoveredByYourGrace": {w: 8, b: 8},
    "Domine": {w: 8, b: 8},
    "LibreBaskerville": {w: 8, b: 8},
    "Kristi": {w: 8, b: 8},
    "Niconne": {w: 8, b: 8},
    "Merriweather": {w: 8, b: 8},
    "MerriweatherLight": {w: 8, b: 8},
    "OldPosthumusStd": {w: 8, b: 8},
};

/**
 * @param fontFamily
 * @param isWhiteText
 * @return {{min: (number), max: number}}
 */
export const getSizeRange = (fontFamily, isWhiteText) => {
    const min = minFontSizes[fontFamily]
        ? isWhiteText
            ? minFontSizes[fontFamily].w
            : minFontSizes[fontFamily].b
        : absoluteMin;

    return {
        min: min || absoluteMin,
        max: absoluteMax,
    }
}
