import config from './designer/config';

let sessionCheckerId;

const checkTimeout = (startTime, ttlInSeconds) => {
    const secondsSinceStart = (new Date() - startTime) / 1000;
    if (secondsSinceStart > (ttlInSeconds)) {
        dismissSessionTimeoutObservation();
        alert(config.messages._session_timeout || 'The session has expired. Page needs to be reloaded.');
        window.location.reload();
    }
};

export const startSessionTimeoutObservation = (ttlInSeconds = 3600, checkEverySeconds = 10) => {
    dismissSessionTimeoutObservation();
    const startTime = new Date();
    sessionCheckerId = setInterval(
        checkTimeout.bind(null, startTime, ttlInSeconds),
        checkEverySeconds * 1000
    );
}

export const dismissSessionTimeoutObservation = () => {
    if (sessionCheckerId) clearTimeout(sessionCheckerId);
}