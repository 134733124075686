import $ from 'jquery';
import MotifEditor from "./motif-editor";
import {getSizeRange} from "../font-sizes";

const fontScalingStep = 1;

export default class NormalMotifEditor extends MotifEditor {

    $lines;
    lines;
    selectedLineId;
    ignoreSelectionInCanvas = false;

    constructor($container) {
        super($container);

        this.$lines = $container.find('.c-lines');
    }


    async initialize(creatorId, docId, name, theme) {
        await super.initialize(creatorId, docId, name, theme);
    }

    async destroy(asynchronous = true) {
        this.$lines.find('input').off();
        this.$lines.empty();
        this.lines = null;
        await super.destroy(asynchronous);
    }

    async _handleWorkspaceLoaded() {
        super._handleWorkspaceLoaded();
        try {
            const textIds = await this.cApp.getTextIds();

            const getTextInfoFunctions = textIds.map(id => this.cApp.getTextInfo(id));
            const lines = await Promise.all(getTextInfoFunctions);
            this.lines = lines.reduce((accu, curr) => ({
                ...accu,
                [curr.id]: {...curr, sizes: getSizeRange(curr.font, curr.hexColor === '#ffffff')},
            }), {});

            lines.forEach(line => {
                this.$lines.append(this._createInput(line.id, line.text, line.textCase));
            });

            this.cApp.setElementSelectedHandler(this._handleSelectionInCanvas.bind(this));
        } catch {
            throw 'Could not initialize line elements.';
        }
    }

    _createInput(textId, initialText, textCase) {
        const $line = $('<input type="text" title="">');
        $line.attr('placeholder', initialText);
        $line.data('placeholder', initialText);
        $line.attr('data-id', textId);

        if (textCase === this.cApp.TO_UPPER) {
            $line.on('input', function () {
                let caretPosition = NormalMotifEditor.getCaretPosition(this);
                this.value = this.value.toUpperCase();
                NormalMotifEditor.setCaretPosition(this, caretPosition);
            });
        }
        if (textCase === this.cApp.TO_LOWER) {
            $line.on('input', function () {
                let caretPosition = NormalMotifEditor.getCaretPosition(this);
                this.value = this.value.toLowerCase();
                NormalMotifEditor.setCaretPosition(this, caretPosition);
            });
        }

        $line.focus(this._selectElement.bind(this, textId));

        let timerId;
        $line.keyup(() => {
            if (timerId) clearTimeout(timerId);
            timerId = setTimeout(() => $line.trigger('change'), 500);
        });

        let currentText = initialText;
        $line.change(() => {
            $line.attr('placeholder', '');

            const enteredText = $line.val();
            if (enteredText === currentText) return;

            this.cApp.setText(textId, enteredText || ' ').then(() => currentText = enteredText);
        });

        return $line;
    }

    _handleSelectionInCanvas(id) {
        if (this.ignoreSelectionInCanvas) return;

        this.selectedLineId = this.lines[id] ? id : null;
        this._updateButtons();
    }

    async _selectElement(id) {
        this.ignoreSelectionInCanvas = true;
        await this.cApp.selectElement(id);
        this.ignoreSelectionInCanvas = false;
        this.selectedLineId = id;
        this._updateButtons();
    }

    async _handleIncreasing() {
        if (this.selectedLineId) {
            const newSize = this.lines[this.selectedLineId].size + fontScalingStep;
            await this.cApp.setTextSize(this.selectedLineId, newSize);
            this.lines[this.selectedLineId].size = newSize;
            this._updateButtons();
        }
    }

    async _handleDecreasing() {
        if (this.selectedLineId) {
            const newSize = this.lines[this.selectedLineId].size - fontScalingStep;
            await this.cApp.setTextSize(this.selectedLineId, newSize);
            this.lines[this.selectedLineId].size = newSize;
            this._updateButtons();
        }

    }

    _updateButtons() {
        this.$btnInc.off('click');
        this.$btnDec.off('click');

        if (this.selectedLineId) {
            const sizes = this.lines[this.selectedLineId].sizes;
            const currentSize = this.lines[this.selectedLineId].size;
            const increasingAllowed = currentSize + fontScalingStep <= sizes.max;
            const decreasingAllowed = currentSize - fontScalingStep >= sizes.min;

            this.$btnInc.toggleClass('c-disabled', !increasingAllowed);
            this.$btnDec.toggleClass('c-disabled', !decreasingAllowed);

            if (increasingAllowed) this.$btnInc.click(this._handleIncreasing.bind(this));
            if (decreasingAllowed) this.$btnDec.click(this._handleDecreasing.bind(this));
        }
    }

    _handleReset() {
        return super._handleReset().then(() => this._resetInputs());
    }

    _resetInputs() {
        this.$lines.find('input').each((i, line) => {
            const $line = $(line);
            return $line.val('').attr('placeholder', $line.data('placeholder'));
        });
    }

    static getCaretPosition(ctrl) {
        let CaretPos = 0;    // IE Support
        if (document.selection) {
            ctrl.focus();
            let Sel = document.selection.createRange();
            Sel.moveStart('character', -ctrl.value.length);
            CaretPos = Sel.text.length;
        }
        // Firefox support
        else if (ctrl.selectionStart || ctrl.selectionStart === 0) {
            CaretPos = ctrl.selectionStart;
        }

        return CaretPos;
    }

    static setCaretPosition(ctrl, pos) {
        if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(pos, pos);
        } else if (ctrl.createTextRange) {
            let range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
        }
    }
}