import $ from 'jquery';

const $configurator = $('.c-site-content');
if ($configurator.length) {

    const $controlPanel = $configurator.find('.c-control');
    const $filterParents = $configurator.find('.c-filter .c-control-items');
    const $filterOptions = $filterParents.find('> *');
    const $allTemplates = $configurator.find('.c-template');
    // const $motifs = $configurator.find('.c-motifs');
    const $pager = $configurator.find('.c-motifs-pager');
    const $appliedFilterContainer = $configurator.find('.c-applied-filters-container');
    const $appliedFilters = $configurator.find('.c-applied-filters');
    const $menuOpenButton = $configurator.find('.c-menu-open-button');
    const $menuCloseButton = $configurator.find('.c-menu-close-button');
    const $modalOverlay = $configurator.find('.modal-overlay');
    const $body = $('body');

    const maxTemplatesPerPage = 48;
    const defaultPagerConfig = {
        totalPages: 6,
        visiblePages: 6,
        startPage: 1,
        hideOnlyOnePage: true,
        loop: true,
        first: false,
        last: false,
        next: '>',
        prev: '<',
        onPageClick: function (event, page) {
            showPageTemplates(page);
            $('html').stop().animate({scrollTop: 0}, 300);
        }
    };

    let blazy = new Blazy({
        selector: '.c-motif-list img',
    });

    function isDesktopMode() {
        return $(document).width() >= 768;
    }

    $(document).click(function () {
        hideControlMenus();
    });

    function hideControlMenus() {
        $controlPanel.find('.c-control-title .glyphicon')
            .addClass('glyphicon-chevron-right')
            .removeClass('glyphicon-chevron-down');
        $controlPanel.find('.c-filter .c-control-items')
            .addClass('hidden');
        $controlPanel.find('.c-color-control .c-control-items')
            .addClass('hidden-xs visible-xs-block');
    }

    function showControlMenu($control) {
        $control.find('.c-control-title .glyphicon')
            .removeClass('glyphicon-chevron-right')
            .addClass('glyphicon-chevron-down');
        $control.filter('.c-filter ').find('.c-control-items')
            .removeClass('hidden');
        $control.filter('.c-color-control').find('.c-control-items')
            .removeClass('hidden-xs');
    }

    function filterTemplates(labelIds, otherLabelIds) {
        if (labelIds && labelIds.length > 0 || otherLabelIds && otherLabelIds.length > 0) {
            let $templatesToShow = $allTemplates.filter(function () {
                let templateLabelIds = ('' + $(this).data('labels')).trim();
                if (templateLabelIds) {
                    const templateLabelIdsArray = templateLabelIds.split(',').map(v => v.trim()).filter(v => !!v);
                    const hasLabel = labelIds.length <= 0 || templateLabelIdsArray.filter(function (n) {
                        return labelIds.indexOf(parseInt(n)) > -1;
                    }).length > 0;
                    const hasOtherLabel = otherLabelIds.length <= 0 || templateLabelIdsArray.filter(function (n) {
                        return otherLabelIds.indexOf(parseInt(n)) > -1;
                    }).length > 0;
                    return hasLabel && hasOtherLabel;
                }
                return false;
            });
            let $templatesToHide = $allTemplates.not($templatesToShow);
            $templatesToShow.removeClass('hidden');
            $templatesToHide.addClass('hidden');
        } else {
            $allTemplates.removeClass('hidden');
        }
        blazy.revalidate();
    }

    function updateAppliedFilters(labelIds) {
        if (labelIds.length) {
            labelIds.sort();
            $appliedFilters.empty();
            labelIds.forEach(labelId => {
                const $filterOption = $filterParents.find('[data-label-id="' + labelId + '"]');
                const text = $filterOption.find('.c-label-name').text();
                const $new = $(
                    '<span class="c-applied-filter">' +
                    '  <span>' + text + '</span>' +
                    '  <span class="glyphicon glyphicon-remove-circle"></span>' +
                    '</span>').attr(
                    {
                        'data-label-id': labelId,
                        'data-show-all': $filterOption.hasClass('c-reset-others') ? '1' : null
                    });
                $appliedFilters.append($new);
            });
            $appliedFilterContainer.show();
        } else {
            $appliedFilterContainer.hide();
            $appliedFilters.empty();
        }
    }

    function updatePager() {
        const $matchingTemplates = $allTemplates.filter(':not(.hidden)');
        let totalPages = $matchingTemplates.length > 0 ? Math.ceil($matchingTemplates.length / maxTemplatesPerPage) : 1;
        $pager.twbsPagination('destroy');
        $pager.twbsPagination($.extend({}, defaultPagerConfig, {totalPages}));
    }

    function showPageTemplates(page) {
        const $matchingTemplates = $allTemplates.filter(':not(.hidden)');
        const firstTemplateToShow = (page - 1) * maxTemplatesPerPage;
        const lastTemplateToShow = firstTemplateToShow + maxTemplatesPerPage - 1;

        $matchingTemplates.each(function (i, template) {
            if (i >= firstTemplateToShow && i <= lastTemplateToShow) $(template).show();
            else $(template).hide();
        });
        blazy.revalidate();
    }

    function showControlPanel(show = true, duration = 300) {
        if (show) {
            $controlPanel.stop().slideDown(duration);
            $modalOverlay.removeClass('hidden');
            $menuCloseButton.removeClass('hidden');
            $menuOpenButton.addClass('hidden');
            $body.css('overflow-y', 'hidden');
        } else if (!isDesktopMode()) {
            $menuCloseButton.addClass('hidden');
            $menuOpenButton.removeClass('hidden');
            $controlPanel.stop().slideUp(duration, () => {
                $modalOverlay.addClass('hidden');
                $body.css('overflow-y', '');
            });
        }
    }

    function prepareDesktopMode() {
        $controlPanel.stop().show();
        $modalOverlay.addClass('hidden');
        $menuCloseButton.addClass('hidden');
        $menuOpenButton.removeClass('hidden');
        $modalOverlay.addClass('hidden');
        $body.css('overflow-y', '');
    }

    function getAppliedLabelIds($templates) {
        let labelIds = new Set();
        $templates.each((index, template) => {
            let templateLabelIds = ('' + $(template).data('labels')).split(',').map(v => v.trim()).filter(v => !!v).map(v => parseInt(v));
            templateLabelIds.forEach(id => labelIds.add(id));
        });
        return labelIds;
    }

    function removeFilterOptionsWithoutTemplates() {
        let appliedIds = getAppliedLabelIds($allTemplates);
        $filterOptions.filter('[data-label-id][data-label-id!=""]').each((index, option) => {
            let $option = $(option);
            let labelId = parseInt($option.data('label-id'));
            if (!appliedIds.has(labelId)) {
                $option.remove();
            }
        });
    }

    function getSelectedFilterOptions($controlItems) {
        return $controlItems.filter('[data-label-id]:not([data-label-id=""])')
            .find('.glyphicon:not(.invisible)').parent()
            .map((i, item) => $(item).data('label-id')).get();
    }

    $controlPanel.find('.c-control-title').click(function (e) {
        const opened = $(this).parent().find('.c-control-items:visible').length > 0;
        hideControlMenus();
        if (!opened) {
            showControlMenu($(this).parent());
            e.stopPropagation();
        }
    });

    $controlPanel.find('.c-color-control .c-control-items > *, .c-own-motif-button').click(function () {
        hideControlMenus();
        showControlPanel(false);
    });

    $filterOptions.click(function () {
        const $this = $(this);
        const $controlItems = $this.parent().children();
        const $reset = $controlItems.filter('.c-reset-others');

        if ($this.hasClass('c-reset-others')) {
            $this.find('.glyphicon').removeClass('invisible');
            $controlItems.not(this).find('.glyphicon').addClass('invisible');
        } else {
            if ($this.find('.glyphicon').hasClass('invisible')) {
                $this.find('.glyphicon').removeClass('invisible');
                $reset.find('.glyphicon').addClass('invisible');
            } else {
                $this.find('.glyphicon').addClass('invisible');
                if ($controlItems.not($reset).find('.glyphicon.invisible').length === $controlItems.not($reset).length) {
                    $reset.find('.glyphicon').removeClass('invisible');
                }
            }
        }
        const labelIdsOfThisGroup = getSelectedFilterOptions($controlItems);
        const labelIdsOfOtherGroups = getSelectedFilterOptions($controlPanel.find('.c-filter .c-control-items').not($this.parent()).children());

        filterTemplates(labelIdsOfThisGroup, labelIdsOfOtherGroups);
        updateAppliedFilters(labelIdsOfThisGroup.concat(labelIdsOfOtherGroups));
        updatePager();
        hideControlMenus();
        showControlPanel(false);
        $('html').stop().animate({scrollTop: 0}, 300);
    });

    $appliedFilters.on('click', '.glyphicon', function () {
        const $appliedFilter = $(this).closest('.c-applied-filter');
        const labelId = $appliedFilter.data('label-id');
        let $filterOption;
        if ($appliedFilter.data('show-all')) {
            $filterOption = $filterParents.find('[data-label-id="' + labelId + '"]').siblings().filter('[data-label-id=""]');
        } else
            $filterOption = $filterParents.find('[data-label-id="' + labelId + '"]');
        $filterOption.trigger('click');
    });

    updatePager();

    $menuOpenButton.click(() => {
        showControlPanel();
    });
    $menuCloseButton.click(() => {
        hideControlMenus();
        showControlPanel(false);
    });

    $(window).resize(() => {
        hideControlMenus();
        if (isDesktopMode()) {
            prepareDesktopMode();
        } else {
            showControlPanel(false, 0);
        }
    });

    removeFilterOptionsWithoutTemplates();

    $('html').scrollTop(0);
    if (isDesktopMode()) {
        prepareDesktopMode();
    } else {
        showControlPanel(false, 0);
    }

}